import { FilterType, IActiveFilter, ISubMenu, t } from 'shared/ui/table/lib/common'

export enum EUploadedFileFilterType {
  UPLOADED_FILE_TYPES = 'uploadedFileTypes',
  STATES = 'states',
  CREATED_DATE = 'createdDate',
  ATTACHED_TO_CASE_DATE = 'attachedToCaseDate',
  DELETED_DATE = 'deletedDate',
}

export type TDateRange = {
  from: string | null
  to: string | null
}

export const updateDateRangeFilter = (
  prevFilters: IActiveFilter[],
  filter: ISubMenu,
  filterKey?: string,
  dateRange?: [string, string],
): IActiveFilter[] => {
  if (!dateRange) {
    return prevFilters.filter((f) => f.key !== filterKey)
  }

  const { filterType, key, title } = filter

  const filterIndex = prevFilters.findIndex((filter) => filter.key === filterKey)

  if (filterIndex !== -1) {
    const updatedFilters = [...prevFilters]
    updatedFilters[filterIndex].activeFilters = [
      {
        dateRange: { from: dateRange[0], to: dateRange[1] },
        filterName: updatedFilters[filterIndex].title,
        filterValue: true,
        key,
      },
    ]

    return updatedFilters
  }

  return [
    ...prevFilters,
    {
      activeFilters: [
        {
          dateRange: { from: dateRange[0], to: dateRange[1] },
          filterName: title,
          filterValue: true,
          key,
        },
      ],
      filterType,
      key,
      title,
    },
  ]
}
// Создание списка подфильтров

export const createUFSubMenu = <T extends string>(
  key: string,
  title: string,
  queryValues: T[],
  filterType: FilterType,
  labelCb: (it: T) => string,
): ISubMenu => ({
  filterType,
  items: queryValues?.map((queryValue, index) => ({
    key: `${queryValue}:${index}`,
    label: t(labelCb(queryValue)),
    queryValue,
  })),
  key,
  title: t(title),
})
