/* eslint-disable i18next/no-literal-string */
import { TableProps } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useThemeContext } from 'app/styled/ThemeProvider'
import icons from 'assets/icons'
import { CaseForm, CaseFormDataType, useMutateCase } from 'features/cases'
import { useCaseDocumentsQuery } from 'features/cases/api/query'
import caseService, { IFile } from 'features/cases/api/service'
import { useCasesTableQuery } from 'features/cases-management/api/query'
import caseManagementService from 'features/cases-management/api/service'
import { PreviewPanel } from 'features/cases-management/CaseInfoBox'
import {
  calculateVisibleRows,
  formatDate,
  getQueryParams,
  refetchPages,
  setSortOrder,
} from 'features/cases-management/lib/helpers'
import { ECaseCursor, ECaseTableType, EDistributionTabType } from 'features/cases-management/types/ECaseTableType'
import { ECaseSortBy } from 'features/cases-management/types/TCasePagination'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useCaseManagementTabContext } from 'features/cases-management/ui/CaseManagementTabContext'
import { DoctorNameCellTable } from 'features/cases-management/ui/doctor-list/DoctorListItem'
import { handleStatusModalClick } from 'features/new-preview-panel/common'
import ReportCreationModalContainer from 'features/reports/ReportCreationModalContainer'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import { TusContext } from 'processes/tus'
import React, { FC, memo, MouseEvent, RefObject, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { QUERY_TYPE } from 'shared/api'
import i18next from 'shared/lib/i18n/i18n'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { DropDown } from 'shared/ui/dropdown/DropDown'
import { TextAreaElement, TextElement } from 'shared/ui/kit'
import { ModalHandle, ModalProvider } from 'shared/ui/modal'
import { Column } from 'shared/ui/table'
import { convertToUpperCaseWithUnderscore } from 'shared/ui/table/lib/helpers'
import { useCustomVt, useTableSort } from 'shared/ui/table/lib/hooks'
import {
  ICaseTableProps,
  StyledDateItem,
  StyledTable,
  StyledTableWrapper,
  StyledTag,
} from 'shared/ui/table/ui/Table.styled'
import styled from 'styled-components/macro'
import { ECaseStageQuery, IAssignedDoctor, ICaseDTO } from 'types/ICase'
import { IPatientDTO } from 'types/IPatient'
import { TCasesManagementTabs } from 'types/TTab'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { VtOpts } from 'virtualizedtableforantd4/dist/esm/vt'

import { CaseName } from './CaseName'
import { dotColors, ECaseStage, StatusModal, StatusModalBody } from './modal/StatusModal'
import { Patient } from './Patient'

export const t = i18next.t

const HOT_KEYS_TABLE_NAVIGATION_UP = 'up, j, о'
const HOT_KEYS_TABLE_NAVIGATION_DOWN = 'down, k, л'
const HOT_KEYS_CASE_INFO_BOX_OPEN = 'enter, o'
const HOT_KEY_TABLE_CHECKBOX_TOGGLE = 'x'
const REASON_OF_REFUSE_MIN_CHAR = 3
const TEXTAREA_ROWS_QUANTITY = 3
const TEXTAREA_MAX_LENGTH = 270
const TEXTAREA_MIN_LENGTH = 3
export const SEC_TO_MINUTES = 60
export const ONE_MINUTE_IN_MS = 60000
const SCROLL_Y = '100%'
/** Оффсет количества элементов, где нужно начинать подгрузку следующей страницы */
const NEXT_PAGE_DOWNLOAD_OFFSET = 5
/** Оффсет количества элементов, на которые скролится предыдущая страница после загрузки */
const UP_SCROLL_OFFSET = 3
/** Высота строки в таблице */
export const ROW_HEIGHT = 44
/** Дефолтное поле для сортировки */
export const DEFAULT_SORT_FIELD = 'caseDate'
/** Дефолтное направление для сортировки */
export const DEFAULT_SORT_DIRECTION = 'ascend'
const ENTER_KEY = 'enter'

enum SortDirection {
  Up = 'up',
  Down = 'down',
}
/** Таймат для того чтобы начать скроллить к выделленому кесу. Нужен для того, чтобы все эффекты успели обработаться и обновились стили*/
const SCROLL_TIMEOUT = 100

interface CasesTableProps {
  tabType: TCasesManagementTabs
}

export type CaseData = { caseId?: number; name?: string | null; wsId?: number }

type TTagItemProps = {
  tag: ECaseStageQuery
  onClick?: (e: MouseEvent) => void
}

type TSelectedCase = { record: ICaseDTO | null; index: number | null }

const getIndexByKey = (key: number, data: ICaseDTO[]) => data.findIndex((item) => item.caseId === key)
const getCaseByKey = (key: number, data: ICaseDTO[]) => data.filter((item) => item.caseId === key)

export const TagItem: FC<TTagItemProps> = memo(({ onClick, tag }) => {
  const colorTheme = useThemeContext()
  return (
    <StyledTag onClick={onClick} color={dotColors.get(ECaseStage[tag])} theme={colorTheme.theme}>
      {ECaseStage[tag]}
    </StyledTag>
  )
})
export const DateItem = memo(({ date, isExpired }: { date?: string; isExpired?: boolean }) => (
  <StyledDateItem>
    <TextElement>{date ? formatDate(date) : t('Не указано')}</TextElement>
    {isExpired && <icons.exclamationMark title={'Срочно!'} />}
  </StyledDateItem>
))

export const CasesTable: React.FC<CasesTableProps> = ({ tabType }) => {
  const queryClient = useQueryClient()
  const { search } = useLocation()
  const [cursor, setCursor] = useState<string | ECaseCursor>()
  const workspaceId = Number(useCurrentWorkspaceId())
  const { filterParams, setVisibleRowsCount, visibleRowsCount } = useCaseManagementContext()
  const { openPanel, setOpenPanel } = useContext(TusContext)
  const { selectedCases, selectedRowKeys, setSelectedCases, setSelectedRowKeys, setTabsQueryParams, tabsQueryParams } =
    useCaseManagementTabContext()
  const { handleSortChange, sortConfig } = useTableSort<ICaseDTO>({
    field: DEFAULT_SORT_FIELD,
    order: DEFAULT_SORT_DIRECTION,
  })
  const { currentTab, menuTab } = useCaseManagementRouteParam()

  const tableContainerRef: RefObject<HTMLDivElement> = useRef(null)
  const { isCaseRouting } = useSettingsAndUserRoles()
  const { casesList, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage, isFetching, isLoading, refetch } =
    useCasesTableQuery(
      menuTab,
      isCaseRouting,
      {
        tab: currentTab,
        ...getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting),
      },
      visibleRowsCount,
      filterParams,
      cursor,
    )

  /** При изменении масштаба страницы, перезапрашиваем данные для таблицы*/
  useEffect(() => {
    refetchPages(
      queryClient,
      menuTab,
      { tab: tabType, ...getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting) },
      filterParams,
      workspaceId,
      visibleRowsCount,
    )
  }, [visibleRowsCount])

  /** Запрос курсора предыдущей страницы (страница может быть от 1 до 20 элементов) */
  const fetchPrevCursor = async (caseId: string) => {
    const { previousPageCursor } = await caseManagementService.getNextPrevCasesCursor(Number(workspaceId), menuTab, {
      caseId: Number(caseId),
      sortBy: getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting)?.sortBy,
      sortDir: getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting)?.sortDir,
    })

    return previousPageCursor
  }

  /** Перзапрос списка кейсов и их количества для таба */
  const refetchCases = () => {
    refetch()
    queryClient.invalidateQueries([QUERY_TYPE.CASE_MANAGEMENT_CASES_COUNT, menuTab])
  }

  /** Параметры поиска кейса в url*/
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  /** CaseId из url*/
  const selectedCaseId = searchParams.get('caseId')

  /** Изменение сортировки */
  useEffect(() => {
    setTabsQueryParams((prevState) => ({
      ...prevState,
      [menuTab]: {
        ...prevState[menuTab],
        [tabType]: {
          queryParams: {
            sortBy:
              (sortConfig?.field && convertToUpperCaseWithUnderscore(sortConfig?.field)) ||
              getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting)?.sortBy,
            sortDir:
              (sortConfig?.order && convertToUpperCaseWithUnderscore(sortConfig?.order)) ||
              getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting).sortDir,
          },
        },
      },
    }))
  }, [sortConfig, menuTab, tabType])

  /** Установка курсора в стейт для инициоравания запроса на список в таблицу */
  useEffect(() => {
    if (selectedCaseId) {
      fetchPrevCursor(selectedCaseId).then((cursor) => {
        setCursor(cursor)
      })
    } else {
      setCursor(ECaseCursor.NULL)
    }
  }, [])

  const declineModal = useRef<ModalHandle | null>(null)
  const caseFormModal = useRef<ModalHandle | null>(null)
  const statusHistoryModalRef: RefObject<ModalHandle> = useRef(null)
  const reportCreationModalRef = useRef<ModalHandle | null>(null)
  const history = useHistory()
  const [selectedCase, setSelectedCase] = useState<TSelectedCase | null>(null)
  const [selectedContextCase, setSelectedContextCase] = useState<ICaseDTO | null>(null)
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null)
  const [declineComment, setDeclineComment] = useState<string>('')
  const { isPanelPreviewVisible, panelPreviewHeight, setPanelPreviewVisible } = useCaseManagementContext()
  const [declineLoading, setDeclineLoading] = useState<boolean>(false)
  const [currentCase, setCurrentCase] = useState<CaseData>({})
  const prevSelectedRowIndexRef = useRef<number | null>(null)
  const isRefuseButtonDisabled = declineComment.length < REASON_OF_REFUSE_MIN_CHAR || declineLoading
  const selectedRecord = selectedCase?.record
  const targetCase = selectedContextCase || selectedRecord
  const { mutateAsync } = useMutateCase(targetCase?.caseId || 0)
  const { data: caseRecordDocs } = useCaseDocumentsQuery({
    caseId: targetCase?.caseId || 0,
    source: 'PLATFORM',
  })
  const { caseId, casePathologicalInfo, caseType, materialTakeoutDate, name, patient } = targetCase || {}
  const onCaseEditSave = async (data: CaseFormDataType, newFiles: IFile[], deletedFiles?: IFile[]) => {
    if (!targetCase) return
    await mutateAsync(
      { data, deletedFiles, newFiles },
      {
        onSuccess: () => {
          refetchPages(
            queryClient,
            menuTab,
            { tab: tabType, ...getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting) },
            filterParams,
            workspaceId,
            visibleRowsCount,
          )

          if (selectedCase) {
            const updatedRecord = queryClient.getQueryData([QUERY_TYPE.CASE, selectedCase?.record?.caseId]) as ICaseDTO

            updatedRecord &&
              setSelectedCase({
                index: selectedCase.index,
                record: updatedRecord,
              })
          }
        },
      },
    )
    caseFormModal.current?.close()
  }

  const onDecline = async () => {
    if (declineComment && targetCase) {
      setDeclineLoading(true)
      await caseService
        .declineCase(String(workspaceId), declineComment, targetCase.caseId)
        .then(() => {
          setDeclineComment('')
          declineModal.current?.close()
          refetchCases()
          setSelectedContextCase(null)
        })
        .catch(() => setDeclineLoading(false))
    }
    setDeclineLoading(false)
  }
  const onDeclineClose = () => {
    declineModal.current?.close()
    setDeclineComment('')
  }

  const handleHotKey = (direction: string, event: KeyboardEvent) => {
    event.preventDefault()
    setSelectedRowIndex((prevIndex) => {
      let newIndex

      if (prevIndex === null) {
        // Выбираем первую строку при нажатии 'down', если до этого ничего не выбранно
        newIndex = direction === SortDirection.Down ? 0 : null
      } else {
        // Обычная навигация, когда строка уже выбрана. Так же это проверка граничных условий, чтоб не влетать в несуществующие индексы.
        newIndex =
          direction === SortDirection.Up ? Math.max(0, prevIndex - 1) : Math.min(casesList.length - 1, prevIndex + 1)
      }

      if (newIndex !== null) {
        setSelectedCase({ index: newIndex, record: casesList[newIndex] })
        setPanelPreviewVisible(true)
      }

      return newIndex
    })
  }

  const resetSelectedRow = () => {
    // Если пользователь кликает снова на уже выбранную запись, закрываем инфобокс
    history.push({
      search: `?${new URLSearchParams({ tab: currentTab })}`,
    })
    setSelectedRowIndex(null)
    setSelectedCase(null)
    setPanelPreviewVisible(false)
  }

  const getRowClassName = (_: ICaseDTO, index: number) => (index === selectedRowIndex ? 'selected-row' : '')
  const getPageNumber = (index?: number | null) => Math.floor((index || 0) / visibleRowsCount)

  const handleRowClick = (record: ICaseDTO, index: number | null) => {
    const caseId = record?.caseId
    const pageNumber = getPageNumber(index)
    // обработка, когда включен режим множественного выбора по чекбоксам
    if (selectedRowKeys.length) {
      const isNewSelection = !selectedCases.find((selCase) => selCase.caseId === Number(caseId))
      const newSelectionData = isNewSelection
        ? [...selectedCases, record]
        : selectedCases.filter((selCase) => selCase.caseId !== Number(caseId))
      const newSelectionRowKeys = isNewSelection
        ? [...selectedRowKeys, `${pageNumber}-${index}-${caseId}`]
        : selectedRowKeys.filter((selRowKey) => !String(selRowKey).includes(String(caseId)))

      onRowSelection(newSelectionRowKeys, newSelectionData)
      return
    }

    if (selectedRecord?.caseId === record?.caseId && isPanelPreviewVisible) {
      resetSelectedRow()
    } else {
      history.push({
        search: `?${new URLSearchParams({ caseId: String(caseId), tab: currentTab })}`,
      })
      setSelectedRowIndex(index ?? null)
      setSelectedCase({ index, record })
      setPanelPreviewVisible(true)
    }
  }

  /** После подгрузки курсора, выделяем нужную строку */
  useEffect(() => {
    if (!isLoading && !isFetching && cursor && cursor !== ECaseCursor.NULL) {
      // кейс, когда выделили первую запись и для нее приходит previousPageCursor
      // TODO бек обещал доработать данную проблему и для первой записи списка не присылать previousPageCursor
      if (!casesList?.length) {
        setCursor(ECaseCursor.NULL)
        // делаем перезапрос макротаской для того, чтобы успел установиться стейт курсора
        setTimeout(() => {
          refetch().then((result) => {
            // индекс первой записи
            setSelectedRowIndex(0)
            // берем первую запись первой (едиственной) страницы
            setSelectedCase({ index: 0, record: result.data?.pages[0][0] as ICaseDTO })
          })
        })
      } else if (hasNextPage) {
        fetchNextPage().then((result) => {
          // берем последнюю загруженную страницу
          const currentPage = result.data?.pages?.at(-1) || []
          // получаем количество записей, перед строкой, которую нужно выделить
          // @ts-ignore
          const previousCount = result.data?.pageParams[0]?.size * (Number(result.data?.pages?.length) - 1)
          const selRecordIndex = currentPage?.findIndex((caseItem) => caseItem.caseId === Number(selectedCaseId))
          if (selRecordIndex !== -1) {
            setPanelPreviewVisible(true)
            const nodeRows = document.querySelectorAll('.ant-table-row')
            // берем последнюю строку
            const lastRow = nodeRows[nodeRows.length - 1]
            setSelectedRowIndex(previousCount + selRecordIndex)
            setSelectedCase({ index: previousCount + selRecordIndex, record: currentPage[selRecordIndex] })
            setTimeout(() => {
              lastRow?.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }, SCROLL_TIMEOUT)
          }
        })
        setCursor(ECaseCursor.NULL)
      }
    }
  }, [isLoading, isFetching, cursor])

  useDeepCompareEffect(() => {
    setSelectedCases((prevSelectedCases) =>
      prevSelectedCases.map((prevSelectedCase) => {
        const updatedValue = casesList.find((updatedValue) => updatedValue.caseId === prevSelectedCase.caseId)
        return updatedValue || prevSelectedCase
      }),
    )

    /** Проверяем наличие выбранного кейса в обновленной таблице */
    if (Number.isInteger(selectedCase?.index) && selectedRecord && !isFetching) {
      const isCaseInList = casesList.find((item) => item.caseId === selectedCase.record?.caseId)
      /** При отсутствии кейса, выбранным кейсом становится следующий за ним */
      if (!isCaseInList) {
        const nextCase = casesList[selectedCase.index as number]
        /** При отсутствии следующего за ним кейса, закрываем панель */
        if (nextCase) {
          setSelectedCase({ index: selectedCase.index, record: nextCase })
        } else {
          setSelectedCase(null)
          setPanelPreviewVisible(false)
        }
      }
    }
    // обработка для открытия панели превью при смене таба, если есть ранее выделенная строка
    if (selectedRowIndex && !isLoading && !isFetching) {
      if (tabType === searchParams.get('tab') && !!selectedCaseId) {
        setSelectedCase({ index: selectedRowIndex, record: casesList[selectedRowIndex] })
        setPanelPreviewVisible(!!casesList[selectedRowIndex])
      } else {
        setSelectedRowIndex(null)
        setPanelPreviewVisible(false)
      }
    }
  }, [casesList, selectedCase, selectedRowIndex, isLoading, isFetching, tabType])

  // сбрасывваем выделение кейса при смене фильтров
  useDeepCompareEffect(() => {
    resetSelectedRow()
  }, [filterParams])

  const onRowSelection = (selectedRowKeys: React.Key[], selectedRows: ICaseDTO[]) => {
    resetSelectedRow()
    setSelectedRowKeys(selectedRowKeys)
    setSelectedCases(selectedRows)
  }

  const rowSelection: TableProps<ICaseDTO>['rowSelection'] = {
    onChange: onRowSelection,
    selectedRowKeys: selectedRowKeys,
  }

  const checkScrollDirection = (): string => {
    let scrollDirection = 'down'
    if (prevSelectedRowIndexRef.current !== null && selectedRowIndex !== null) {
      scrollDirection = selectedRowIndex > prevSelectedRowIndexRef.current ? 'down' : 'up'
    }
    return scrollDirection
  }
  const tusFileId = useTypedSelector((state) => state.tusFiles.queue[0])
  const tusFileIds = useTypedSelector((state) => state.tusFiles.queue).length
  const file = useTypedSelector((state) => state.tusFiles.byId[tusFileId || ''])
  const fileCaseId = file?.caseId

  useEffect(() => {
    const csId = selectedContextCase?.caseId
    if (!csId) return
    const caseData = getCaseByKey(csId, casesList)[0]
    const indexCaseId = getIndexByKey(csId, casesList)
    if (openPanel && csId && selectedRecord?.caseId !== caseData?.caseId) {
      handleRowClick(caseData, indexCaseId ?? null)
      setOpenPanel(false)
    }
  }, [fileCaseId, tusFileIds, openPanel, selectedRecord?.caseId])

  useEffect(() => {
    if (tusFileIds && openPanel) {
      setOpenPanel(false)
    }
  }, [openPanel, tusFileIds])

  useEffect(() => {
    if (!isPanelPreviewVisible) {
      setSelectedCase(null)
      setOpenPanel(false)
    }
  }, [isPanelPreviewVisible])

  useEffect(() => {
    const currentSelectedRow = document.querySelector('.selected-row') as HTMLElement | null
    const previousRow = currentSelectedRow?.previousElementSibling as HTMLElement | null
    const tableOverFlowWrapper = document.querySelector('.over-flow-table-wrapper') as HTMLElement | null

    if (currentSelectedRow !== null && previousRow !== null) {
      if (checkScrollDirection() === 'down') {
        currentSelectedRow.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      } else {
        previousRow.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }
    //Скролл в начало таблицы, когда скролим вверх и нужно доскролить до первого ряда
    if (tableOverFlowWrapper !== null && previousRow === null) {
      tableOverFlowWrapper.scrollTo({ behavior: 'smooth', top: 0 })
    }
    prevSelectedRowIndexRef.current = selectedRowIndex
  }, [selectedRowIndex])

  useHotkeys(HOT_KEYS_TABLE_NAVIGATION_UP, (event) => handleHotKey('up', event), [selectedRowIndex])
  useHotkeys(HOT_KEYS_TABLE_NAVIGATION_DOWN, (event) => handleHotKey('down', event), [selectedRowIndex])
  useHotkeys(
    HOT_KEYS_CASE_INFO_BOX_OPEN,
    () => {
      if (!isPanelPreviewVisible && selectedRowIndex !== null) {
        setSelectedCase({ index: selectedRowIndex, record: casesList[selectedRowIndex] })
        setPanelPreviewVisible(true)
      }
    },
    [isPanelPreviewVisible, selectedRowIndex, casesList],
  )
  useHotkeys(
    HOT_KEY_TABLE_CHECKBOX_TOGGLE,
    () => {
      if (selectedRowIndex !== null) {
        const key = casesList[selectedRowIndex].caseId
        setSelectedRowKeys((prevKeys) => {
          const isKeySelected = prevKeys.includes(key)
          return isKeySelected ? prevKeys.filter((k) => k !== key) : [...prevKeys, key]
        })
      }
    },
    [selectedRowIndex, casesList, selectedRowKeys],
  )

  const closeCaseInfoBox = () => {
    history.push({
      search: `?${new URLSearchParams({ tab: currentTab })}`,
    })
    setSelectedRowIndex(null)
    setPanelPreviewVisible(false)
  }

  const onTableUpScroll = async () => {
    const tableOverFlowWrapper = document.querySelector('.ant-table-body') as HTMLElement | null
    if (hasPreviousPage) {
      await fetchPreviousPage()
      tableOverFlowWrapper?.scrollTo({ behavior: 'smooth', top: ROW_HEIGHT * UP_SCROLL_OFFSET })
    }
  }

  const onTableDownScroll = () => {
    hasNextPage && fetchNextPage()
  }

  const onTableScroll: VtOpts['onScroll'] = async ({ isEnd, top }) => {
    if (!isFetching) {
      !top ? onTableUpScroll() : isEnd && onTableDownScroll()
    }
  }

  const [tableBodyHeight, setTableBodyHeight] = useState(0)

  const [vt] = useCustomVt(
    { onScroll: onTableScroll, scroll: { y: tableBodyHeight - ROW_HEIGHT * NEXT_PAGE_DOWNLOAD_OFFSET } },
    [isFetching, tableBodyHeight],
  )

  const onRow = (record: ICaseDTO, index?: number) => ({
    key: index,
    onClick: () => handleRowClick(record, index ?? null),
    onContextMenu: () => setSelectedContextCase(record),
  })
  const onCloseContextMenu = () => setSelectedContextCase(null)

  useHotkeys(
    ENTER_KEY,
    () => {
      !declineLoading && onDecline()
    },
    [declineLoading],
  )

  /** Обработчкик события клика на Enter TextArea */
  const onPressEnterTextArea = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    !declineLoading && onDecline()
  }

  useEffect(() => {
    const resizeHandle = () => {
      setPanelPreviewVisible(false)
      calculateVisibleRows(tableContainerRef, setTableBodyHeight, setVisibleRowsCount)
    }
    /** Рассчитываем количество строк при монтировании компонента */
    resizeHandle()
    /** Добавляем обработчик изменения размера окна для пересчета количества строк */
    window.addEventListener('resize', resizeHandle)
    return () => {
      /** Убираем обработчик при размонтировании компонента  */
      window.removeEventListener('resize', resizeHandle)
    }
  }, [])

  return (
    <StyledTableWrapper
      data-testid={'table-item'}
      ref={tableContainerRef}
      panelPreviewHeight={panelPreviewHeight}
      isPanelPreviewVisible={isPanelPreviewVisible}
    >
      <DropDown
        setCurrentCase={setCurrentCase}
        caseFormModal={caseFormModal}
        refetchCases={refetchCases}
        caseData={selectedContextCase}
        declineModal={declineModal}
        statusHistoryModal={statusHistoryModalRef}
        reportCreationModal={reportCreationModalRef}
      >
        <StyledTable<React.FC<ICaseTableProps>>
          scroll={{ y: tableBodyHeight || SCROLL_Y }}
          components={vt}
          rowClassName={getRowClassName}
          rowSelection={tabType in EDistributionTabType ? rowSelection : undefined}
          dataSource={casesList}
          showSorterTooltip={false}
          pagination={false}
          tableLayout="fixed"
          onRow={onRow}
          onChange={handleSortChange}
          loading={isLoading || isFetching}
          rowKey={({ caseId }, index) => {
            const pageNumber = getPageNumber(index)
            return `${pageNumber}-${index}-${caseId}`
          }}
        >
          <Column
            className="has-divider"
            dataIndex="patient"
            key="patient"
            render={(patient: IPatientDTO | null) => {
              if (!patient) return t('Неизвестно')
              if (!patient?.fullname) return t('Скрыто')
              return (
                <Patient
                  key={patient.patientProfileId}
                  patientName={patient.fullname}
                  patientConnectedCases={patient.availableCasesCount}
                />
              )
            }}
            title={t('Пациент')}
          />
          <Column
            className="has-divider"
            dataIndex="name"
            key="caseName"
            render={(text: string, record: ICaseDTO) => {
              const { caseId, slideStats, status } = record
              return (
                <CaseName
                  isArchive={status === 'ARCHIVE'}
                  key={caseId}
                  countDone={slideStats.slidesCount}
                  countTotal={slideStats.referencesCount}
                  caseName={text}
                />
              )
            }}
            title={t('Случай')}
          />
          {menuTab === ECaseTableType.ROUTING && tabType !== EDistributionTabType.NOT_ASSIGNED && (
            <Column
              className="has-divider"
              dataIndex="assignedDoctor"
              key="assignedDoctor"
              render={(doctor: IAssignedDoctor | null) => {
                if (!doctor || !doctor?.fullname) return t('Не назначен')
                const { fullname, userId } = doctor
                return <DoctorNameCellTable doctor={fullname} key={userId} />
              }}
              title={t('Врач')}
            />
          )}
          <Column
            className="has-divider"
            dataIndex="diagnosticProcedureType"
            key="diagnosticProcedureType"
            render={(_, record: ICaseDTO) => {
              const { casePathologicalInfo } = record
              if (!casePathologicalInfo || !casePathologicalInfo.diagnosticProcedureType)
                return <TextElement ellipsis>{t('Неизвестно')}</TextElement>

              const { diagnosticProcedureType } = casePathologicalInfo
              return <TextElement ellipsis>{diagnosticProcedureType.name}</TextElement>
            }}
            sorter={true}
            title={t('Способ получения')}
            sortOrder={setSortOrder(tabsQueryParams, menuTab, tabType, ECaseSortBy.DIAGNOSTIC_PROCEDURE_TYPE)}
          />
          <Column
            className="twoRows has-divider"
            dataIndex="incomingIcd10"
            key="incomingIcd10"
            render={(_, record: ICaseDTO) => {
              const { casePathologicalInfo } = record
              if (!casePathologicalInfo || !casePathologicalInfo.incomingIcd10)
                return <TextElement ellipsis>{t('Неизвестно')}</TextElement>

              const { incomingIcd10 } = casePathologicalInfo
              return (
                <TextElement ellipsis>
                  {incomingIcd10.code} {incomingIcd10.name}
                </TextElement>
              )
            }}
            sorter={true}
            title={t('МКБ-10')}
            sortOrder={setSortOrder(tabsQueryParams, menuTab, tabType, ECaseSortBy.INCOMING_ICD_10)}
          />
          <Column
            width={120}
            className="has-divider"
            dataIndex="caseDate"
            key="caseDate"
            render={(date: string, record: ICaseDTO) => <DateItem date={date} isExpired={record.urgent} />}
            sorter={true}
            title={t('Дата')}
            sortOrder={setSortOrder(tabsQueryParams, menuTab, tabType, ECaseSortBy.CASE_DATE)}
          />
          {isCaseRouting && (
            <Column
              width={130}
              dataIndex="stage"
              key="stage"
              render={(tag: ECaseStageQuery, record: ICaseDTO) => <TagItem tag={tag} />}
              sorter={true}
              title={t('Статус')}
              sortOrder={setSortOrder(tabsQueryParams, menuTab, tabType, ECaseSortBy.STAGE)}
            />
          )}
        </StyledTable>
      </DropDown>
      {isPanelPreviewVisible && (
        <PreviewPanel
          setCurrentCase={setCurrentCase}
          declineModal={declineModal}
          refetch={refetchCases}
          caseFormModal={caseFormModal}
          selectedCase={selectedRecord || null}
          statusHistoryModal={statusHistoryModalRef}
          onClose={closeCaseInfoBox}
          closeContextMenu={onCloseContextMenu}
          reportCreationModalRef={reportCreationModalRef}
        />
      )}
      <ModalProvider ref={caseFormModal} centered={true} footer={null} closable={false} destroyOnClose>
        <CaseForm
          onSave={onCaseEditSave}
          isEdit={true}
          onCancel={caseFormModal.current?.close}
          initialValues={{
            caseId,
            casePathologicalInfo,
            caseType,
            materialTakeoutDate: materialTakeoutDate ? new Date(materialTakeoutDate) : undefined,
            name: name || '',
            patient,
          }}
          caseRecordDocs={caseRecordDocs}
        />
      </ModalProvider>
      <DeclineModalProvider
        confirmLoading={declineLoading}
        ref={declineModal}
        onOk={onDecline}
        okText={t('Отказаться')}
        onCancel={onDeclineClose}
        centered={true}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ disabled: isRefuseButtonDisabled, style: { marginLeft: 0, width: '100%' } }}
        destroyOnClose
        title={t('Укажите причину отказа')}
      >
        <TextAreaElement
          rows={TEXTAREA_ROWS_QUANTITY}
          value={declineComment}
          maxLength={TEXTAREA_MAX_LENGTH}
          minLength={TEXTAREA_MIN_LENGTH}
          onChange={(e) => setDeclineComment(e.target.value)}
          onPressEnter={onPressEnterTextArea}
        />
      </DeclineModalProvider>
      <StatusModal
        title={currentCase.name || ''}
        ref={statusHistoryModalRef}
        destroyOnClose
        onCancel={(e) => {
          setCurrentCase({})
          handleStatusModalClick(statusHistoryModalRef, e)
        }}
        footer={null}
        centered={true}
      >
        <StatusModalBody currentCase={currentCase} />
      </StatusModal>
      {targetCase && (
        <ReportCreationModalContainer
          modalRef={reportCreationModalRef}
          caseId={targetCase?.caseId}
          source={'PLATFORM'}
        />
      )}
    </StyledTableWrapper>
  )
}

const DeclineModalProvider = styled(ModalProvider)`
  & .ant-modal-content .ant-modal-body {
    width: 400px !important;
    padding: 0 16px 16px 16px;
  }
`
