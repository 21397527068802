import React, { createContext, FC, ReactNode, useContext, useState } from 'react'

type TUploadedFileContextProps = {
  searchString: string
  setSearchString: React.Dispatch<React.SetStateAction<string>>
  autocompleteSearchList: string[]
  setAutocompleteSearchList: React.Dispatch<React.SetStateAction<string[]>>
}

const initialUploadedFileContext: TUploadedFileContextProps = {
  autocompleteSearchList: [],
  searchString: '',
  setAutocompleteSearchList: () => {},
  setSearchString: () => {},
}

const UploadedFileContext = createContext<TUploadedFileContextProps>(initialUploadedFileContext)

export const useUploadedFileContext = () => useContext(UploadedFileContext)

const UploadedFileProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [searchString, setSearchString] = useState('')
  const [autocompleteSearchList, setAutocompleteSearchList] = useState<string[]>([])

  return (
    <UploadedFileContext.Provider
      value={{
        autocompleteSearchList,
        searchString,
        setAutocompleteSearchList,
        setSearchString,
      }}
    >
      {children}
    </UploadedFileContext.Provider>
  )
}

export default UploadedFileProvider
