import { CaseName } from 'features/cases-management/ui/cases-table/CaseName'
import { TagItem } from 'features/cases-management/ui/cases-table/CasesTable'
import WarningPreview from 'features/new-preview-panel/WarningPreview'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateForDisplay } from 'shared/lib/date'
import { Column } from 'shared/ui/table'
import { ICaseTableProps, StyledTable } from 'shared/ui/table/ui/Table.styled'
import ICase, { ECaseStageQuery, ICaseDTO, ICasePathologicalInfo } from 'types/ICase'
import IUser from 'types/IUser'
import { IWorkspace } from 'types/IWorkspaces'

type Props = {
  /** Выбранные связанные случаи. */
  selectedRelated?: ICase[]
  /** Комментарий к отказу. */
  declinementComment?: string
  /** Флаг загрузки данных. */
  isFetching: boolean
}

const RelatedCasesTabContent = ({ declinementComment, isFetching, selectedRelated }: Props) => {
  const { t } = useTranslation()
  return (
    <div>
      {declinementComment && <WarningPreview title={declinementComment} />}
      <div style={{ display: 'flex' }}>
        <StyledTable<React.FC<ICaseTableProps>>
          style={{ border: `none` }}
          tableLayout={'fixed'}
          dataSource={selectedRelated}
          loading={isFetching}
          pagination={false}
        >
          <Column
            title={t('МЕДУЧРЕЖДЕНИЕ')}
            dataIndex="workspace"
            key="workspace"
            render={(workspace: IWorkspace) => <>{workspace?.name || ''}</>}
            className="has-divider"
          />
          <Column
            className="has-divider"
            title={t('Случай')}
            dataIndex="name"
            key="name"
            render={(text: string, record: ICaseDTO) => {
              const { caseId, slideStats, status } = record
              return (
                <CaseName
                  isArchive={status === 'ARCHIVE'}
                  key={caseId}
                  countDone={slideStats.slidesCount}
                  countTotal={slideStats.referencesCount}
                  caseName={text}
                />
              )
            }}
          />
          <Column
            title={t('ВРАЧ')}
            className="has-divider"
            dataIndex="user"
            key="user"
            render={(user: IUser) => <>{user?.fullname ?? t('Автоматически')}</>}
          />
          <Column
            title={t('СПОСОБ ПОЛУЧЕНИЯ')}
            className="has-divider"
            dataIndex="casePathologicalInfo"
            key="casePathologicalInfo"
            render={(info: ICasePathologicalInfo) => <>{info?.diagnosticProcedureType?.name || ''}</>}
          />
          <Column
            title={t('МКБ-10')}
            className="has-divider"
            dataIndex="casePathologicalInfo"
            key="casePathologicalInfo"
            render={(info: ICasePathologicalInfo) => <>{info?.incomingIcd10?.name || ''}</>}
          />
          <Column
            title={t('ДАТА')}
            dataIndex="caseDate"
            key="caseDate"
            render={(caseDate: string, record: ICase) => (
              <>{formatDateForDisplay(caseDate || record.createdAt || '')}</>
            )}
            className="has-divider"
          />
          <Column
            title={t('СТАТУС')}
            dataIndex="stage"
            key="stage"
            render={(text: ECaseStageQuery) => <TagItem tag={text} />}
          />
        </StyledTable>
      </div>
    </div>
  )
}

export default RelatedCasesTabContent
