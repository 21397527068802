import stubLabel from 'assets/thumbnails/stub-label.png'
import React from 'react'
import { IconElement, ImageElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'

type Props = {
  /** Обработчик контекстного меню. */
  handleContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void
  /** Угол поворота этикетки. */
  rotateLabel?: number
  /** Данные выбранного файла. */
  selectedFile: IUploadedFileDTO
}

const UploadedFilePreviewImage = ({ handleContextMenu, rotateLabel, selectedFile }: Props) => (
  <StyledContainer onContextMenu={handleContextMenu}>
    {selectedFile?.fileType === 'MACRO' && (
      <StyledImage fileType={selectedFile?.fileType} src={selectedFile?.thumbnails.medium} />
    )}
    {selectedFile?.fileType === 'DOCUMENT' && (
      <IconElement name="pdfPreview" style={{ height: '100%', width: '100%' }} />
    )}
    {selectedFile?.fileType === 'MICRO' && (
      <div style={{ display: 'flex', height: 115, width: 230 }}>
        <StyledImage src={selectedFile?.thumbnails.medium} />
        <StyledImage
          style={{ transform: rotateLabel !== undefined ? `rotate(${rotateLabel}deg)` : 'none' }}
          src={selectedFile?.labelUrl || stubLabel}
        />
      </div>
    )}
  </StyledContainer>
)

export default UploadedFilePreviewImage

const StyledContainer = styled.div`
  display: flex;
  flex: 0 1 115px;
  width: 230px;
  border: 2px solid var(--color-border-1);
  border-radius: 3px;
  overflow: hidden;
`

const StyledImage = styled(ImageElement)<{ fileType?: string }>`
  background-color: var(--color-white);
  border-radius: 0;
  height: ${({ fileType }) => (fileType ? '100%' : '115px')};
  width: ${({ fileType }) => (fileType ? '100%' : '115px')};
`
