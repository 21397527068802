import React, { createContext, FC, ReactNode, useContext, useState } from 'react'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'

type TUploadedFileTabContextProps = {
  /** Выбранный файл (по клику) */
  selectedFile?: IUploadedFileDTO
  setSelectedFile: React.Dispatch<React.SetStateAction<IUploadedFileDTO | undefined>>
  /** Ключи выбранных строк */
  selectedRowKeys: React.Key[]
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
  /** Выбранные чек-боксом файлы */
  selectedFiles: IUploadedFileDTO[]
  setSelectedFiles: React.Dispatch<React.SetStateAction<IUploadedFileDTO[]>>
  resetSelectedRows: () => void
}

const initialUploadedFileTabContext: TUploadedFileTabContextProps = {
  resetSelectedRows: () => {},
  selectedFile: undefined,
  selectedFiles: [],
  selectedRowKeys: [],
  setSelectedFile: () => {},
  setSelectedFiles: () => {},
  setSelectedRowKeys: () => {},
}

const UploadedFileTabContext = createContext<TUploadedFileTabContextProps>(initialUploadedFileTabContext)

export const useUploadedFileTabContext = () => useContext(UploadedFileTabContext)

const UploadedFileProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = useState<IUploadedFileDTO[]>(initialUploadedFileTabContext.selectedFiles)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(initialUploadedFileTabContext.selectedRowKeys)
  const [selectedFile, setSelectedFile] = useState<IUploadedFileDTO | undefined>(
    initialUploadedFileTabContext.selectedFile,
  )
  const resetSelectedRows = () => {
    setSelectedRowKeys([])
    setSelectedFiles([])
  }

  return (
    <UploadedFileTabContext.Provider
      value={{
        resetSelectedRows,
        selectedFile,
        selectedFiles,
        selectedRowKeys,
        setSelectedFile,
        setSelectedFiles,
        setSelectedRowKeys,
      }}
    >
      {children}
    </UploadedFileTabContext.Provider>
  )
}

export default UploadedFileProvider
