export enum QUERY_TYPE {
  SLIDE = 'slide',
  SLIDE_GROUPED = 'slide_grouped',
  SIMILAR = 'similarSlide',
  CASE = 'case',
  CASE_MANAGEMENT = 'caseManagement',
  CASES = 'cases',
  CASE_DOCS = 'caseDocs',
  CASE_BIO_MATERIALS = 'caseBioMaterials',
  CASE_DOCTORS_LIST = 'caseDoctorsList',
  CASE_REFERENCE_SLIDES = 'caseReferencesSlides',
  REPORT = 'report',
  REPORT_DATA = 'reportData',
  PERMISSION = 'permission',
  USERS = 'users',
  NOTIFICATION = 'notification',
  COMMENT = 'comment',
  NOTE = 'note',
  ANNOTATION = 'annotation',
  ANNOTATIONS_STACK = 'annotationStack',
  ANNOTATION_INFO = 'annotationInfo',
  STAIN = 'stain',
  SITE = 'site',
  MORPH = 'morph',
  ICD_10 = 'icd10',
  SEARCH_GRID = 'searchGrid',
  IIIF_CONFIG = 'iiif',
  HEAT_MAP = 'heatMap',
  SEGMENTATION = 'segmentation',
  SLIDE_VIEWED = 'slideViewed',
  ATLAS = 'atlas',
  TASKS = 'tasks',
  TASKS_SLIDES = 'tSlides',
  TASKS_CLASSES = 'tClasses',
  TASKS_SLIDE = 'tSlide',
  LABEL_IMAGE = 'labelImage',
  WORKSPACES = 'workspaces',
  PATIENT = 'patient',
  PROCEDURE_TYPE = 'procedureType',
  MODALITIES = 'modalities',
  DEPARTMENT = 'department',
  PATIENT_CASES_BY_PROFILE = 'patientCasesByProfile',
  PATIENT_CASES = 'patientCases',
  CACHERS = 'сachers',
  UPLOADED_FILE = 'uploadedFile',
  UPLOADED_FILE_COUNT = 'uploadedFileCount',
  UPLOADED_ALL_FILES = 'uploadedAllFiles',
  UPLOADED_DUPLICATE_FILES = 'uploadedDuplicateFiles',
  BARCODE_REASON = 'barcodeReason',
  SUMP_FILES_COUNT = 'sumpFilesCount',
  UPLOADED_FILES_FILTERS = 'uploadedFilesFilters',
  SLIDE_DATA_LAYERS = 'slideDataLayers',
  USERS_BY_CASE = 'usersByCase',
  DATA_LAYER_GEO_JSON = 'dataLayerGeoJson',
  CASE_MANAGEMENT_DOCTORS = 'caseManagementDoctors',
  CASE_MANAGEMENT_CASES_COUNT = 'caseManagementCasesCount',
}
