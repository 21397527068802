import UploadFileTableFilter from 'features/uploaded-file/ui/filter/UploadFileTableFilter'
import UploadedFileProvider from 'features/uploaded-file/ui/table/UploadedFileContext'
import UploadedFileTabContext from 'features/uploaded-file/ui/UploadedFileTabContext'
import UploadedFileTabs from 'features/uploaded-file/ui/UploadedFileTabs'
import React from 'react'
import TableLayout from 'shared/ui/table/ui/TableLayout'

const UploadedFileRoute = () => (
  <UploadedFileProvider>
    <TableLayout>
      <UploadFileTableFilter />
      <UploadedFileTabContext>
        <UploadedFileTabs />
      </UploadedFileTabContext>
    </TableLayout>
  </UploadedFileProvider>
)

export default UploadedFileRoute
