import { TabPaneProps, Tabs, TabsProps } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { useAvailableMenuTabs, useSettingsAndUserRoles } from 'shared/lib/workspaces'
import styled from 'styled-components'

type Props = TabPaneProps & TabsProps

const StyledTabPane = styled(Tabs.TabPane)`
  height: 100%;
`

export enum NavigationTab {
  /** Распределение */
  ROUTING = 'routing',
  /** Мои случаи (Поменяли местами названия табов 1 и 2 уровня, эндпоинт остался прежним, поэтому worklist) */
  WORKLIST = 'worklist',
  /** Все случаи */
  WORKSPACE = 'workspace',
  /** Атлас */
  ATLAS = 'atlas',
  /** Задачи */
  TASKS = 'tasks',
  /** Загрузка случаев */
  WAREHOUSE = 'warehouse',
  CREATE_CASE = 'create',
}

export const NavigationHeader: FC<Props> = ({ children, ...rest }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isLisMode = useLisMode() === 'none'
  const { menuTab } = useCaseManagementRouteParam()
  const { filterParams, setFilterParams } = useCaseManagementContext()
  const {
    isRoutingVisible,
    isWorklistVisible,
    isWorkspaceVisible,
    roles: {
      isRoleAtlasValidation: isAtlasAvailable,
      isRoleGuest,
      isRoleMarkupUser: isTaskAvailable,
      isRoleUnsortedImageManage: isSumpAvailable,
    },
  } = useSettingsAndUserRoles()
  const visibleTabs = useAvailableMenuTabs() as unknown[]
  const defaultActiveTab = ((menuTab as unknown) || visibleTabs[0]) as NavigationTab
  const workspaceId = useTypedSelector((state) => state.workspaces.currentWorkspace)?.workspaceId
  const { setPanelPreviewVisible } = useCaseManagementContext()
  const [activeTab, setActiveTab] = useState<NavigationTab>(defaultActiveTab)
  const location = useLocation()

  useEffect(() => {
    setPanelPreviewVisible(false)
    if (~location.pathname.indexOf('routing')) {
      return setActiveTab(NavigationTab.ROUTING)
    }
    if (~location.pathname.indexOf('worklist')) {
      return setActiveTab(NavigationTab.WORKLIST)
    }
    if (~location.pathname.indexOf('create')) {
      return setActiveTab(NavigationTab.CREATE_CASE)
    }
    if (~location.pathname.indexOf('warehouse')) {
      return setActiveTab(NavigationTab.WAREHOUSE)
    }
    if (~location.pathname.indexOf('workspace')) {
      return setActiveTab(NavigationTab.WORKSPACE)
    }
    if (~location.pathname.indexOf('atlas') && activeTab !== NavigationTab.ATLAS) {
      return setActiveTab(NavigationTab.ATLAS)
    }
    if (~location.pathname.indexOf('tasks') && activeTab !== NavigationTab.TASKS) {
      return setActiveTab(NavigationTab.TASKS)
    }
  }, [location.pathname])

  const onTabClick = (key: NavigationTab) => {
    setActiveTab(key)
    if ([NavigationTab.TASKS, NavigationTab.ATLAS].includes(key)) {
      dispatch(push(`/${key}`))
      return
    }

    if (key === NavigationTab.WAREHOUSE) {
      dispatch(push(`/workspace/${workspaceId}/warehouse`))
      return
    }
    if (key === NavigationTab.CREATE_CASE) {
      dispatch(push(`/workspace/${workspaceId}/create`))
      return
    }

    if ((key as string) !== menuTab) {
      /** Очищаем фильтры */
      setFilterParams({})
      dispatch(push(`/management/${key}`))
      return
    }
  }

  return (
    <StyledTabs
      {...rest}
      defaultActiveKey={menuTab ?? activeTab}
      type="card"
      tabBarGutter={8}
      activeKey={menuTab ?? activeTab}
      size="small"
      onTabClick={(key) => onTabClick(key as NavigationTab)}
      onChange={() => setPanelPreviewVisible(false)}
    >
      {isRoutingVisible && <Tabs.TabPane key={NavigationTab.ROUTING} tab={t('Распределение')} />}

      {isWorklistVisible && (
        <StyledTabPane key={NavigationTab.WORKLIST} tab={<span data-testid="my-cases-item">{t('Мои случаи')}</span>}>
          {children}
        </StyledTabPane>
      )}

      {isWorkspaceVisible && (
        <StyledTabPane key={NavigationTab.WORKSPACE} tab={<span data-testid="all-cases-item">{t('Все случаи')}</span>}>
          {children}
        </StyledTabPane>
      )}

      {isSumpAvailable && (
        <Tabs.TabPane
          key={NavigationTab.WAREHOUSE}
          tab={<span data-testid="warehouse-item">{t('Загрузка слайдов')}</span>}
        >
          {children}
        </Tabs.TabPane>
      )}

      {isAtlasAvailable && (
        <Tabs.TabPane key={NavigationTab.ATLAS} tab={<span data-testid="atlas-item">{t('Атлас')}</span>}>
          {children}
        </Tabs.TabPane>
      )}

      {isTaskAvailable && (
        <Tabs.TabPane key={NavigationTab.TASKS} tab={<span data-testid="tasks-item">{t('Задачи')}</span>}>
          {children}
        </Tabs.TabPane>
      )}
      {isLisMode && !isRoleGuest && (
        <Tabs.TabPane
          key={NavigationTab.CREATE_CASE}
          tab={<span data-testid="new-case-item">{t('Новый случай')}</span>}
        >
          {children}
        </Tabs.TabPane>
      )}
    </StyledTabs>
  )
}

const StyledTabs = styled(Tabs)`
  && .ant-tabs {
    width: 100%;
  }

  && .ant-tabs-tab {
    padding: 4px 8px !important;
    background: var(--color-bg-1);
    color: var(--color-text-3);
    font-size: 14px;
    line-height: 20px;
    margin: 0 !important;
    border-radius: 5px;
    transition-duration: 0s;

    & :hover {
      color: var(--color-text-1);
    }
  }

  && .ant-tabs-tab.ant-tabs-tab-active {
    padding: 4px 8px;
    color: var(--color-text-1);
    background: var(--color-border-1);
    border-radius: 5px;
  }

  && .ant-tabs-nav-operations {
    display: none;
  }
  && .ant-tabs-nav {
    margin: 0;
  }
`
