import { useTypedSelector } from 'app/redux/lib/selector'
import UploadedFileSearchContainer from 'features/uploaded-file/ui/search/UploadedFileSearchContainer'
import UploadedFileSwitcherContainer from 'features/uploaded-file/ui/switcher/UploadedFileSwitcherContainer'
import React, { FC } from 'react'
import styled from 'styled-components/macro'

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const UploadedFileActions: FC = ({ children }) => {
  const dupBarcode = useTypedSelector((state) => state.viewerPage.sumpDuplicateBarcodeShowNumber)

  return (
    <StyledWrapper>
      {children && <ActionButtonsWrapper>{children}</ActionButtonsWrapper>}

      <ExtraContent>
        {!dupBarcode.length && <UploadedFileSearchContainer />}

        <UploadedFileSwitcherContainer />
      </ExtraContent>
    </StyledWrapper>
  )
}

export default UploadedFileActions

const ActionButtonsWrapper = styled.div`
  display: flex;
  gap: 4px;
  margin-left: -6px;
  align-items: flex-end;
  align-self: flex-end;
  height: 100%;
  margin-bottom: -4px;
`

const ExtraContent = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
`
