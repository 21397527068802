import { Switch } from 'antd'
import React, { FC } from 'react'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

const StyledSwitch = styled(Switch)`
  height: 32px;
  width: 64px;
  z-index: 1;

  .ant-switch-handle {
    z-index: 3;
    width: 28px;
    height: 28px;
    border-radius: 100%;

    &::before {
      border-radius: 100%;
      background-color: var(--color-bg-4);
    }
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 28px - 2px);
  }

  &.ant-switch-checked:focus,
  &.ant-switch:focus {
    box-shadow: none;
  }
`
const StyledWrapper = styled.div<{ isActive: boolean }>`
  position: relative;
  height: 32px;
  width: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .uploaded-file-switcher {
    background: var(--color-border-1);
  }

  .sump-switcher {
    &-card {
      position: absolute;
      top: 2px;
      left: 1px;

      color: ${({ isActive }) => (isActive ? 'var(--color-text-3)' : 'var(--color-text-1)')};
      z-index: ${({ isActive }) => (isActive ? 0 : 2)};
    }

    &-table {
      position: absolute;
      top: 2px;
      right: 1px;
      z-index: ${({ isActive }) => (isActive ? 2 : 0)};

      color: ${({ isActive }) => (isActive ? 'var(--color-text-1)' : 'var(--color-text-3)')};
    }
  }
`

type TProps = {
  checked: boolean
  handleChecked: () => void
}

const UploadedFileSwitcher: FC<TProps> = ({ checked, handleChecked }) => (
  <StyledWrapper isActive={checked}>
    <StyledSwitch checked={checked} onClick={handleChecked} className="uploaded-file-switcher" disabled />
    <IconElement className="sump-switcher-card" name={'sumpCard'} style={{ height: '28px', width: '28px' }} />
    <IconElement className="sump-switcher-table" name={'sumpTable'} style={{ height: '28px', width: '28px' }} />
  </StyledWrapper>
)

export default UploadedFileSwitcher
