import { useTypedSelector } from 'app/redux/lib/selector'
import slideService from 'entities/slide/api/service'
import { useCaseQuery } from 'features/cases'
import { useCaseReferencesQuery } from 'features/cases/api/query'
import { CaseReference } from 'features/cases/api/service'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getWorkspaceIdUrl } from 'shared/lib/workspaces'
import { ConfirmationModal } from 'shared/ui/confirmationModal'
import { ButtonElement, TextElement } from 'shared/ui/kit'
import ICase from 'types/ICase'

import { StyledOption, StyledText } from './Search.styles'

type SelectedReferenceType = {
  referenceId?: number
  slideExternalId?: string
}

type Props = {
  /** Идентификатор случая. */
  caseId: number
  /**  Функция для привязки загруженных файлов к конкретному случаю (кейсу). */
  onAttachUploadFiles: (targetCase?: ICase, targetCaseSlideReferenceId?: number) => void
}

const ReferencesSlidesList = ({ caseId, onAttachUploadFiles }: Props) => {
  const { t } = useTranslation()
  const workspaceId =
    useTypedSelector((state) => state.workspaces.currentWorkspace)?.workspaceId ||
    getWorkspaceIdUrl(window.location.href)
  const { data: caseResult } = useCaseQuery({ caseId: Number(caseId), source: 'PLATFORM' })
  const { data: caseReferencesSlides } = useCaseReferencesQuery({
    caseId: caseId,
    workspaceId: Number(workspaceId),
  })

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [selectedReference, setSelectedReference] = useState<SelectedReferenceType>()

  const handleCancel = useCallback(() => {
    setIsModalVisible(false)
    setSelectedReference(undefined)
  }, [])

  const handleReplace = useCallback(async () => {
    const filteredDeleteSlide = caseResult?.slides?.filter(
      (slide) => slide.barcode === selectedReference?.slideExternalId,
    )
    if (filteredDeleteSlide && filteredDeleteSlide.length > 0 && !!caseResult?.caseId) {
      await slideService.deleteSingleSlide(caseResult?.caseId, filteredDeleteSlide[0]?.slideId)
      onAttachUploadFiles(caseResult, selectedReference?.referenceId)
    }
  }, [caseResult, selectedReference])

  const handleKeepBoth = useCallback(() => {
    onAttachUploadFiles(caseResult, selectedReference?.referenceId)
  }, [caseResult, onAttachUploadFiles, selectedReference?.referenceId])

  const handleAttachFiles = useCallback(() => {
    if (caseResult) {
      onAttachUploadFiles(caseResult)
    }
  }, [caseResult, onAttachUploadFiles])

  const buttons = [
    { onClick: handleReplace, text: t('Заменить существующее новым') },
    { onClick: handleKeepBoth, text: t('Оставить оба') },
    { onClick: handleCancel, text: t('Отмена (ничего не делать)') },
  ]

  const barcodes = caseResult?.slides?.map((slide) => slide.barcode)

  /** Свободные стекла */
  const filteredReferenceSlides = useMemo(
    () => caseReferencesSlides?.filter((slide) => !barcodes?.includes(slide.slideExternalId)),
    [caseReferencesSlides, barcodes],
  )
  /** Привязанные стекла */
  const linkedSlides = useMemo(
    () => caseReferencesSlides?.filter((slide) => barcodes?.includes(slide.slideExternalId)),
    [caseReferencesSlides, barcodes],
  )

  return (
    <>
      {isModalVisible && <ConfirmationModal title={t('К стеклу уже привязано изображение')} buttons={buttons} />}
      {!!filteredReferenceSlides?.length && (
        <UploadedFileItem
          title={t('Свободные стекла')}
          referenceSlides={filteredReferenceSlides}
          onClick={(item) => onAttachUploadFiles(caseResult, item.caseSlideReferenceId)}
        />
      )}
      {!!linkedSlides?.length && (
        <UploadedFileItem
          title={t('Привязанные стекла')}
          referenceSlides={linkedSlides}
          onClick={(item) => {
            setIsModalVisible(true)
            setSelectedReference({ referenceId: item.caseSlideReferenceId, slideExternalId: item.slideExternalId })
          }}
        />
      )}
      <ButtonElement onClick={handleAttachFiles} style={{ width: '100%' }}>
        {t('Привязать без выбора стекла')}
      </ButtonElement>
    </>
  )
}

export default ReferencesSlidesList

type UploadedFileItemProps = {
  /** Заголовок раздела. */
  title: string
  /** Список стекол. */
  referenceSlides: CaseReference[]
  /** Функция для обработки клика по элементу. */
  onClick: (item: CaseReference) => void
}

const UploadedFileItem = ({ onClick, referenceSlides, title }: UploadedFileItemProps) => (
  <div style={{ width: '100%' }}>
    <div style={{ padding: 8 }}>
      <TextElement type="secondary">{title}</TextElement>
    </div>
    <div style={{ overflow: 'auto' }}>
      {referenceSlides.map((item) => (
        <StyledOption key={item.caseSlideReferenceId} onClick={() => onClick(item)} style={{ display: 'flex' }}>
          <StyledText style={{ color: 'var(--color-text-1)', fontWeight: 400 }}>{item.slideExternalId}</StyledText>
          <StyledText style={{ color: 'var(--color-text-1)', fontWeight: 400 }}>{item?.stain?.code}</StyledText>
        </StyledOption>
      ))}
    </div>
  </div>
)
