import { ContextMenuItem, ContextMenuWrapper } from 'features/thumbnails/ui/ContextThumbnail/ContextThumbnail.styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement } from 'shared/ui/kit'

type Props = {
  /** Массив из двух чисел, определяющий позицию контекстного меню. */
  menuPosition: number[]
  /** Функция, вызываемая при клике на кнопку поворота этикетки. */
  handleRotateLabel: () => void
}

const ContextMenuUploadedFile = ({ handleRotateLabel, menuPosition }: Props) => {
  const { t } = useTranslation()
  return (
    <ContextMenuWrapper thumbnailMenuPosition={menuPosition} position={'absolute'}>
      <ContextMenuItem onClick={handleRotateLabel} isDisabled={false} name={'rotate90Small'}>
        <IconElement size={'md'} name={'rotate90Small'} />
        {`${t('Повернуть этикетку на')} 90°`}
      </ContextMenuItem>
    </ContextMenuWrapper>
  )
}

export default ContextMenuUploadedFile
