import { Typography } from 'antd'
import type { TooltipPlacement } from 'antd/es/tooltip'
import type { TextProps } from 'antd/lib/typography/Text'
import { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import { TooltipElement } from './TooltipElement'

const { Text } = Typography

export const StyledText = styled(Text)<{ isellipsis: boolean }>`
  &.ant-typography {
    font-size: 12px;
    line-height: 16px;
    color: var(--color-text-1);
    overflow: ${({ isellipsis }) => (isellipsis ? 'hidden' : 'visible')};
    text-overflow: ${({ isellipsis }) => (isellipsis ? 'ellipsis' : 'clip')};
  }
  &.ant-typography.ant-typography-secondary {
    color: var(--color-text-3);
  }
  &.ant-typography.ant-typography-danger {
    color: var(--color-red);
  }
`

type Props = {
  tooltipPlacement?: TooltipPlacement
} & TextProps

export const TextElement: FC<Props> = ({ children, ellipsis, tooltipPlacement, ...props }) => {
  const textRef = useRef<HTMLDivElement>(null)
  const [isOverflowed, setIsOverflowed] = useState(false)

  useEffect(() => {
    const { current } = textRef
    if (current) {
      setIsOverflowed(current.scrollWidth > current.clientWidth)
    }
  }, [children])

  if (!ellipsis) {
    return (
      <StyledText isellipsis={false} {...props}>
        {children}
      </StyledText>
    )
  }

  return (
    <TooltipElement title={isOverflowed ? children : undefined} placement={tooltipPlacement}>
      <StyledText {...props} ref={textRef} ellipsis={!!ellipsis} isellipsis={!!ellipsis}>
        {children}
      </StyledText>
    </TooltipElement>
  )
}
