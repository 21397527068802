import React, { useState } from 'react'

import UploadedFileSwitcher from './UploadedFileSwitcher'

const UploadedFileSwitcherContainer = () => {
  const [checked, setChecked] = useState<boolean>(true)

  const handleChecked = () => {
    setChecked((prev) => !prev)
  }

  return <UploadedFileSwitcher checked={checked} handleChecked={handleChecked} />
}

export default UploadedFileSwitcherContainer
