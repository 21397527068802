import caseService from 'features/cases/api/service'
import { notices } from 'features/notices'
import { AttachUploadedFilesQueryType } from 'features/sump'
import { useUploadedFileTabContext } from 'features/uploaded-file/ui/UploadedFileTabContext'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { TFunction } from 'i18next'
import { StompClientContext, useSubscription } from 'processes/stomp'
import { WsResponseCasesList } from 'processes/stomp/types'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UseMutateAsyncFunction } from 'react-query'
import { HttpStatus } from 'shared/api/httpStatusCode'
import { formatDateForDisplay } from 'shared/lib/date'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import Highlighted from 'shared/ui/highlighted'
import { Center, EmptyElement, IconElement } from 'shared/ui/kit'
import ICase from 'types/ICase'
import { v4 as uuidv4 } from 'uuid'

import ReferencesSlidesList from './ReferencesSlidesList'
import {
  CustomDivider,
  FirstLineSearchOption,
  OptionsWrapper,
  StyledInput,
  StyledOption,
  StyledText,
  Wrapper,
} from './Search.styles'

const uuid = uuidv4()
const MAX_HISTORY_LENGTH = 5
const MIN_VALUE_LENGTH = 2

/** Тип данных для запроса на привязку */
type AttachFilesType = UseMutateAsyncFunction<void, unknown, AttachUploadedFilesQueryType, unknown>

type Props = {
  /** Функция для асинхронной привязки файлов. */
  attachFiles: AttachFilesType
}

const CaseSearch = ({ attachFiles }: Props) => {
  const { t } = useTranslation()
  const { publish } = useContext(StompClientContext)
  const workspaceId = useCurrentWorkspaceId()
  const isLisMode = useLisMode() === 'none'
  const {
    roles: { isRoleGuest },
  } = useSettingsAndUserRoles()
  const { resetSelectedRows, selectedFile, selectedFiles, setSelectedFile, setSelectedFiles } =
    useUploadedFileTabContext()
  const [isFocusInput, setFocusInput] = useState<boolean>(false)
  const [options, setOptions] = useState<ICase[]>([])
  const [selected, setSelected] = useState<ICase | undefined>()
  const [selectedHistory, setSelectedHistory] = useState<ICase[]>([])
  const [searchValue, setSearchValue] = useState<string>('')

  const toggleFocus = () => setFocusInput((prevState) => !prevState)

  const clearAll = () => {
    setOptions([])
    setSelected(undefined)
    setSearchValue('')
    resetSelectedRows()
    setSelectedFile(undefined)
    setSelectedFiles([])
  }

  useSubscription<WsResponseCasesList>(`/user/topic/autocomplete/cases/${uuid}`, (result) =>
    setOptions(result?.payload),
  )

  const onSearch = (query: string) => {
    setSearchValue(query)
    setSelected(undefined)
    if (query.length > 1) {
      publish(`/app/workspace/${workspaceId}/case/search/${uuid}`, {
        query,
        searchArchivedCases: true,
        searchOwnCases: true,
        searchSharedCases: false,
      })
    }
  }

  const onSelect = (option: ICase) => {
    onSearch(option?.name || '')
    setSelected(option)
    setSelectedHistory((prevState) => {
      const isDuplicate = prevState.some((item) => item.caseId === option.caseId)
      if (isDuplicate) {
        return prevState
      }
      if (prevState.length === MAX_HISTORY_LENGTH) {
        return [...prevState.slice(1), option]
      } else {
        return [...prevState, option]
      }
    })
  }

  const onMoveFile = async (targetCase?: ICase, targetCaseSlideReferenceId?: number) => {
    let newCase = null
    if (!targetCase && searchValue) {
      try {
        newCase = await caseService.createCaseByExternalId(searchValue)
      } catch (e: any) {
        if (e.response.status === HttpStatus.CONFLICT) {
          notices.error({
            key: 'existing-case-error',
            message: t('Случай уже существует.'),
          })
        }
        if (e?.response?.status === HttpStatus.TEAPOT) {
          notices.error({
            message: t('Превышено максимальное количество медицинских случаев в рамках лицензии'),
          })
        }
      }
    }

    const slidesIds = selectedFile ? [selectedFile?.uploadedFileId] : selectedFiles.map((slide) => slide.uploadedFileId)

    const payload = {
      targetCaseId: targetCase?.caseId || newCase?.caseId || 0,
      uploadedFileIds: slidesIds,
      ...(targetCaseSlideReferenceId && { targetCaseSlideReferenceId }),
    }

    if (payload.targetCaseId) {
      await attachFiles(payload, {
        onError: clearAll,
        onSuccess: clearAll,
      })
    }
  }

  useEffect(() => {
    if (searchValue.length < 2) {
      setOptions([])
    }
  }, [searchValue.length])

  const isRenderOptions =
    !selected && (!!options.length || !!selectedHistory.length || searchValue.length > MIN_VALUE_LENGTH)
  const isRenderNewCaseOption = searchValue.length > MIN_VALUE_LENGTH && isLisMode && !isRoleGuest
  const isRenderEmpty = !options.length && !isLisMode && isRoleGuest && searchValue.length > MIN_VALUE_LENGTH

  return (
    <>
      <Wrapper inFocus={isFocusInput} isSelectedCase={!!selected} isOptions={!!options.length}>
        <div style={{ padding: 8 }}>
          <StyledInput
            isSelectedCase={!!selected}
            prefix={<IconElement size={'md'} name="search" />}
            onFocus={toggleFocus}
            onBlur={toggleFocus}
            autoFocus
            placeholder={t('Случай, заказ или пациент')}
            value={searchValue}
            onChange={(e) => onSearch(e?.target?.value)}
          />
        </div>
        {isRenderEmpty && (
          <Center style={{ marginBottom: 16 }}>
            <EmptyElement />
          </Center>
        )}
        {isRenderOptions && (
          <>
            <CustomDivider />
            <OptionsWrapper>
              {(!!options.length || searchValue.length > MIN_VALUE_LENGTH ? options : selectedHistory).map((it) =>
                createOption(it, searchValue, onSelect, t),
              )}
              {isRenderNewCaseOption && (
                <StyledOption onClick={() => onMoveFile()}>
                  <StyledText style={{ fontWeight: 400 }}>{t('Создать новый случай')}</StyledText>
                  <StyledText style={{ fontWeight: 600 }}>{searchValue}</StyledText>
                </StyledOption>
              )}
            </OptionsWrapper>
          </>
        )}
      </Wrapper>
      {!!selected && <ReferencesSlidesList onAttachUploadFiles={onMoveFile} caseId={selected.caseId} />}
    </>
  )
}
export default CaseSearch

const createOption = (
  option: ICase,
  searchValue: string,
  onClick: (option: ICase) => void,
  t: TFunction<'translation', undefined>,
) => {
  const mapper = Array.from({ length: 1 }).map(() => ({
    name: option.name,
    orderNumber: option.orderNumber,
    patient: option.patient,
  }))

  return (
    <StyledOption key={option.caseId} onClick={() => onClick(option)}>
      {mapper.map(({ name, orderNumber, patient: { birthDate, fullname } = { birthDate: '', fullname: '' } }) => (
        <>
          <FirstLineSearchOption>
            <Highlighted
              style={{
                fontSize: 14,
                fontWeight: 400,
                lineHeight: '20px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              key={name}
              text={name}
              search={searchValue}
            />
            {birthDate && (
              <Highlighted
                style={{
                  fontSize: 12,
                  fontWeight: 400,
                  lineHeight: '16px',
                }}
                key={birthDate}
                text={formatDateForDisplay(birthDate)}
                search={searchValue}
              />
            )}
          </FirstLineSearchOption>
          {orderNumber && (
            <Highlighted
              key={orderNumber}
              style={{
                fontSize: 12,
                fontWeight: 400,
                lineHeight: '16px',
              }}
              text={`${t('Номер заказа')}: ${orderNumber}`}
              search={searchValue}
            />
          )}
          {fullname && <Highlighted key={fullname} text={fullname} search={searchValue} />}
        </>
      ))}
    </StyledOption>
  )
}
