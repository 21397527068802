import 'antd/es/date-picker/style/index'

import { Divider, Menu } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox'
import { useLiveQuery } from 'dexie-react-hooks'
import { useCaseManagementDoctorsQuery } from 'features/cases-management/api/query'
import { EAllCasesTabType, ECaseTableType } from 'features/cases-management/types/ECaseTableType'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { caseStagesFilters } from 'features/cases-management/ui/filters/filters'
import { TDict } from 'features/dictionaries'
import { DictionaryNS } from 'features/dictionaries/api/service'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import React, { FC, useCallback, useEffect, useState } from 'react'
import useFilteredDoctors from 'shared/lib/hooks/useFilteredDoctors'
import i18next from 'shared/lib/i18n/i18n'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { CheckboxElement, SearchElement } from 'shared/ui/kit'
import { TableFilter } from 'shared/ui/table'
import {
  convertDateForQuery,
  createAssignedDoctorsMenu,
  createSubMenu,
  DataFilterQuery,
  DateType,
  fetchDictionaryData,
  FilterQueryKey,
  FilterType,
  IActiveFilter,
  ISubMenu,
  ISubMenuItem,
  ITableRequestConfig,
  menuConfig,
  removeAssignedDoctors,
  updateFilters,
} from 'shared/ui/table/lib/common'
import {
  ContainerSubmenu,
  DateMenuItem,
  LabelContainer,
  SubMenuTitle,
  UserAvatar,
} from 'shared/ui/table/ui/TableFilter'
import styled from 'styled-components'
import useDeepCompareEffect from 'use-deep-compare-effect'

export const t = i18next.t

export const CasesTableFilter: FC = () => {
  const [selectedFilters, setSelectedFilters] = useState<IActiveFilter[]>([])
  const [lastSelectedDoctorId, setLastSelectedDoctorId] = useState<string>()
  const [searchTerm, setSearchTerm] = useState('')
  const [dataMenuConfig, setDataMenuConfig] = useState<ISubMenu[]>(menuConfig)

  const { filterParams, setFilterParams } = useCaseManagementContext()
  const { currentTab, menuTab } = useCaseManagementRouteParam()
  const wsId = Number(useCurrentWorkspaceId())
  const { data: doctors } = useCaseManagementDoctorsQuery(wsId)
  const filteredDoctors = useFilteredDoctors({ doctors, filterParams, menuTab, searchTerm })
  const dictionaryDiagnostic = useLiveQuery(() => fetchDictionaryData(DictionaryNS.DIAGNOSTIC_PROCEDURE_TYPE)) as TDict
  const dictionaryMaterialType = useLiveQuery(() => fetchDictionaryData(DictionaryNS.MATERIAL_TYPE)) as TDict
  const { data: diagnosticData } = { ...dictionaryDiagnostic }
  const { data: materialTypeData } = { ...dictionaryMaterialType }
  const [subMenuKey, setSubMenuKeyFilter] = useState('')
  const { isCaseRouting } = useSettingsAndUserRoles()

  const diagnosticSubMenu = createSubMenu(
    diagnosticData,
    FilterQueryKey.DIAGNOSTIC_PROCEDURE_TYPE_ID,
    t('Способ получения материала'),
  )
  const materialSubMenu = createSubMenu(materialTypeData, FilterQueryKey.MATERIAL_TYPE_ID, t('Тип материала'))

  useDeepCompareEffect(() => {
    if (menuTab === ECaseTableType.ROUTING && filteredDoctors?.length) {
      setDataMenuConfig((prevConfig) => {
        removeAssignedDoctors(prevConfig)
        const assignedDoctors = createAssignedDoctorsMenu(filteredDoctors, t('Назначенный врач'))
        return [assignedDoctors, ...prevConfig]
      })
    }
  }, [[menuTab], filteredDoctors])

  useEffect(
    () => () => {
      setDataMenuConfig((prevConfig) => {
        removeAssignedDoctors(prevConfig)
        return [...prevConfig]
      })
    },
    [menuTab],
  )

  useEffect(() => {
    if (diagnosticData && !dataMenuConfig.some((item) => item.key === diagnosticSubMenu.key)) {
      setDataMenuConfig((prev) => [...prev, diagnosticSubMenu])
    }
  }, [diagnosticData, dataMenuConfig])

  useEffect(() => {
    if (materialTypeData && !dataMenuConfig.some((item) => item.key === materialSubMenu.key)) {
      setDataMenuConfig((prev) => [...prev, materialSubMenu])
    }
  }, [materialTypeData, dataMenuConfig])

  useEffect(() => {
    setSelectedFilters(() => [])
  }, [currentTab, menuTab])

  useEffect(() => {
    setFilterParams(mapFiltersForRequest(selectedFilters))
  }, [selectedFilters])

  const mapFiltersForRequest = (selectedFilters: IActiveFilter[]): ITableRequestConfig =>
    selectedFilters.reduce<ITableRequestConfig>((acc, filter) => {
      switch (filter.key) {
        case FilterQueryKey.URGENT: {
          // Фильтр срочность может быть либо один, либо никакого.
          if (filter.activeFilters.length === 1) {
            acc[FilterQueryKey.URGENT] = filter.activeFilters[0].queryValue
          }
          break
        }
        case FilterQueryKey.DATE_REGISTRATION: {
          if (filter.activeFilters.length > 0 && filter.activeFilters[0].dateRange) {
            acc[DataFilterQuery.FROM] =
              convertDateForQuery(filter.activeFilters[0].dateRange?.from, DateType.FROM) || undefined
            acc[DataFilterQuery.TO] =
              convertDateForQuery(filter.activeFilters[0].dateRange?.to, DateType.TO) || undefined
          }

          break
        }
        case FilterQueryKey.STAGE: {
          // Фильтр статус, в виде массива строк
          acc[FilterQueryKey.STAGE] = filter.activeFilters.map((activeFilter) => activeFilter.queryValue as string)
          break
        }
        case FilterQueryKey.ASSIGNED_DOCTORS: {
          // Фильтр статус, в виде массива строк
          acc[FilterQueryKey.ASSIGNED_DOCTORS] = filter.activeFilters.map(
            (activeFilter) => activeFilter.queryValue as string,
          )
          break
        }
        case FilterQueryKey.SLIDES_COUNT: {
          acc[FilterQueryKey.SLIDES_COUNT] = filter.activeFilters[0].queryValue
          break
        }
        case FilterQueryKey.MATERIAL_TYPE_ID:
        case FilterQueryKey.DIAGNOSTIC_PROCEDURE_TYPE_ID: {
          acc[filter.key] = filter.activeFilters.map((activeFilter) => activeFilter.queryValue as number)
          break
        }
        default:
          filter.activeFilters.forEach((activeFilter) => {
            acc[filter.key] = activeFilter.queryValue !== undefined ? activeFilter.queryValue : activeFilter.filterValue
          })
      }

      return acc
    }, {})

  const isFilterActive = (itemKey: string) =>
    selectedFilters.some((filter) => filter.activeFilters.some((activeFilter) => activeFilter.key === itemKey))

  const handleDataRangeChange = (dates: [Date | null, Date | null] | null, dateStrings: [string, string]) => {
    setSelectedFilters((prevFilters) =>
      updateFilters(
        prevFilters,
        null,
        true,
        {
          filterType: FilterType.DATA_RANGE,
          items: [],
          key: FilterQueryKey.DATE_REGISTRATION,
          title: t('Дата регистрации в ЛИС'),
        },
        dateStrings,
      ),
    )
  }

  const assignedDoctorUserId = filterParams?.assignedDoctorUserId

  useEffect(() => {
    if (assignedDoctorUserId?.length) {
      setLastSelectedDoctorId(String(assignedDoctorUserId[assignedDoctorUserId.length - 1]))
    } else {
      setLastSelectedDoctorId('')
    }
  }, [assignedDoctorUserId])

  const handleFilterChange = (item: ISubMenuItem, subMenu: ISubMenu) => (e: CheckboxChangeEvent) => {
    setSelectedFilters((prevFilters) => updateFilters(prevFilters, item, e.target.checked, subMenu))
  }

  const handleRemoveFilter = useCallback((subMenuKey: React.Key) => {
    setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter.key !== subMenuKey))
  }, [])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleTitleClick = (subMenuKey: string) => setSubMenuKeyFilter(subMenuKey)

  const menu = dataMenuConfig.map((subMenu) => {
    let menuContent

    const dateRange = selectedFilters.filter((it) => it.key === subMenuKey)[0]?.activeFilters[0]?.dateRange

    if (subMenu.key.startsWith(FilterQueryKey.STAGE)) {
      menuContent = caseStagesFilters({ currentTab, handleFilterChange, isCaseRouting, isFilterActive })
    } else {
      switch (subMenu.filterType) {
        case FilterType.SINGLE_SELECT:
        case FilterType.CHECKBOX:
          menuContent = subMenu.items.map((item, index) => (
            <React.Fragment key={`${item.key || index}-${index}`}>
              <Menu.Item className="cases-filter-submenu-item">
                {/** Обёртка для предотвращения закрытия саб меню при клике */}
                <div onClick={(e) => e.stopPropagation()}>
                  <CheckboxElement
                    style={{ alignItems: 'center' }}
                    onChange={handleFilterChange(item, subMenu)}
                    checked={isFilterActive(item.key)}
                  >
                    {subMenu.key === FilterQueryKey.ASSIGNED_DOCTORS ? (
                      <ContainerSubmenu>
                        <UserAvatar numberValue={index} fullname={item.label} />
                        <LabelContainer>{item.label}</LabelContainer>
                      </ContainerSubmenu>
                    ) : (
                      item.label
                    )}
                  </CheckboxElement>
                </div>
              </Menu.Item>
              {subMenu.key === FilterQueryKey.ASSIGNED_DOCTORS && String(item.queryValue) === lastSelectedDoctorId && (
                <Divider />
              )}
            </React.Fragment>
          ))
          break

        case FilterType.DATA_RANGE:
          menuContent = (
            <DateMenuItem
              handleDataRangeChange={handleDataRangeChange}
              valueFrom={dateRange?.from}
              valueTo={dateRange?.to}
              open={subMenuKey === subMenu.key}
            />
          )

          break

        default:
          setSubMenuKeyFilter('')
          menuContent = null
      }
    }

    if (currentTab === EAllCasesTabType.DELETED && subMenu.key.startsWith(FilterQueryKey.STAGE)) {
      return null
    }

    return (
      <Menu.SubMenu
        key={subMenu.key}
        popupClassName={subMenu.filterType === FilterType.DATA_RANGE ? '' : 'submenu-filter-style'}
        title={<SubMenuTitle title={subMenu.title} />}
        onTitleClick={() => handleTitleClick(subMenu.key)}
      >
        {subMenu.key === FilterQueryKey.ASSIGNED_DOCTORS && (
          <>
            <StyledSearchElement
              value={searchTerm}
              onChange={handleSearchChange}
              prefix={null}
              placeholder={`${t(`Поиск`)}`}
            />
            <Divider />
          </>
        )}
        {menuContent}
      </Menu.SubMenu>
    )
  })

  return (
    <TableFilter
      dataMenuConfig={dataMenuConfig}
      handleRemoveFilter={handleRemoveFilter}
      selectedFilters={selectedFilters}
      handleFilterChange={handleFilterChange}
      handleDataRangeChange={handleDataRangeChange}
      setSubMenuKeyFilter={setSubMenuKeyFilter}
      menu={menu}
    />
  )
}

const StyledSearchElement = styled(SearchElement)`
  border-radius: 5px;
  height: 24px;
  width: 94%;
  margin: 8px;
`
