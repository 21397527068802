import { getBarcodeFailText } from 'pages/uploaded-file/lib/renderHandlers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Descriptions, DescriptionsItem } from 'shared/ui/description'
import styled from 'styled-components/macro'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'

type Props = {
  /** Данные выбранного файла. */
  selectedFile?: IUploadedFileDTO
}

const UploadedFileDescription = ({ selectedFile }: Props) => {
  const { t } = useTranslation()

  return (
    <StyledDescriptions>
      <DescriptionsItem label={t('Имя')} span={3}>
        {selectedFile?.fileName}
      </DescriptionsItem>
      <DescriptionsItem label={t('ШК')} span={3}>
        {selectedFile?.barcodes && selectedFile?.barcodes[0].barcode}
      </DescriptionsItem>
      <DescriptionsItem label={t('Путь')} span={3}>
        {selectedFile?.path}
      </DescriptionsItem>
      <DescriptionsItem label={t('Проблема')} span={3}>
        {selectedFile?.state && getBarcodeFailText(selectedFile?.state)}
      </DescriptionsItem>
    </StyledDescriptions>
  )
}

export default UploadedFileDescription

const StyledDescriptions = styled(Descriptions)`
  tbody {
    display: grid;
    gap: 4px;
  }
  .ant-descriptions-item-container {
    line-height: initial;
  }
`
