import { EMyCasesTabType } from 'features/cases-management/types/ECaseTableType'
import { getBooleanOrDefaultAccess } from 'shared/lib/workspaces'
import ICase from 'types/ICase'
import { ISelectedBbox } from 'types/ISimilarRegion'
import ISlide, { IGroupType } from 'types/ISlide'
import { IWorkspace, SettingsNameEnum } from 'types/IWorkspaces'
import { TCasesManagementTabs } from 'types/TTab'

import { MenuItemAction } from './ContextThumbnail.types'

type TypeItem = {
  action: MenuItemAction
  name: string
  text: string
}

const isLabelsCase = (slides?: ISlide[]) =>
  slides && !!slides.filter(({ labelUrl, proxiedLabelUrl }) => proxiedLabelUrl || labelUrl).length

const shouldRoleCondition = (
  /** Проверка на роль пользователя */
  isImageManager?: boolean,
  /** Тeкущая вкладка */
  currentTab?: TCasesManagementTabs,
  /** Проверка relation RESTRICTED случая */
  isRestricted?: boolean,
  /** Текущий workspace */
  currentWorkspace?: IWorkspace | null,
  /** Выбранный случай */
  caseRecord?: ICase,
  /** Проверка на уровень доступности случая */
  isReadOnly?: boolean,
  /** Данные слайдов в результате поиска похожих */
  similarThumbnailHover?: ISelectedBbox,
  /** Проверка relation SHARED случая */
  isShared?: boolean,
) => {
  if (
    isImageManager &&
    currentTab === EMyCasesTabType.INCOMING &&
    !isShared &&
    (currentWorkspace?.workspaceId === caseRecord?.workspace?.workspaceId || isRestricted)
  ) {
    return true
  }
  if ((isImageManager && !isReadOnly && !isShared) || (isImageManager && !similarThumbnailHover && !isShared)) {
    return true
  }

  return false
}

const shouldRotatedCondition = (
  /** Проверка на уровень доступности случая */
  isReadOnly?: boolean,
  /** Тип слайда */
  groupType?: IGroupType,
  /** Данные слайдов в результате поиска похожих */
  similarThumbnailHover?: ISelectedBbox,
  /** Список слайдов */
  slides?: ISlide[],
) => {
  const isAccessRotate = isLabelsCase(slides)
  if ((!isReadOnly || !similarThumbnailHover) && groupType !== 'MACRO' && isAccessRotate) {
    return true
  }
  return false
}

const shouldLabelCondition = (labelUrl?: string, groupType?: IGroupType) => labelUrl && groupType !== 'MACRO'

const shouldDeleteCondition = (
  /** Текущий workspace */
  currentWorkspace: IWorkspace | null,
  /** Проверка на уровень доступности случая */
  isReadOnly?: boolean,
  /** Данные слайдов в результате поиска похожих */
  similarThumbnailHover?: ISelectedBbox,
  /** Проверка relation случая */
  isRestricted?: boolean,
) => {
  if (!isReadOnly || !similarThumbnailHover || !isRestricted) {
    return true
  }
  return getBooleanOrDefaultAccess(SettingsNameEnum.CanRemoveImage, true, currentWorkspace)
}

export const filterItems = (
  /** Элемент меню */
  item: TypeItem,
  /** Текущий workspace */
  currentWorkspace: IWorkspace | null,
  /** Проверка на роль пользователя */
  isImageManager?: boolean,
  /** Проверка на уровень доступности случая */
  isReadOnly?: boolean,
  /** Тип слайда */
  groupType?: IGroupType,
  /** Ссылка на этикетку */
  labelUrl?: string,
  /** Данные слайдов в результате поиска похожих */
  similarThumbnailHover?: ISelectedBbox,
  /** Проверка relation RESTRICTED случая */
  isRestricted?: boolean,
  /** Тeкущая вкладка */
  currentTab?: TCasesManagementTabs,
  /** Отключаем кнопку добавления на разделенный экран */
  addToSplitScreenDisable?: boolean,
  /** Выбранный случай */
  caseRecord?: ICase,
  /** Проверка relation SHARED случая */
  isShared?: boolean,
) => {
  if (!addToSplitScreenDisable && item.action === MenuItemAction.AddToSplitScreen) {
    return true
  }

  const slides = caseRecord?.slides

  switch (item.action) {
    case MenuItemAction.Download:
      return shouldRoleCondition(
        isImageManager,
        currentTab,
        isRestricted,
        currentWorkspace,
        caseRecord,
        isReadOnly,
        similarThumbnailHover,
        isShared,
      )
    case MenuItemAction.RotateAllLabels:
      return shouldRotatedCondition(isReadOnly, groupType, similarThumbnailHover, slides)
    case MenuItemAction.RotateLabel:
      return shouldLabelCondition(labelUrl, groupType)
    case MenuItemAction.Delete:
      return shouldDeleteCondition(currentWorkspace, isReadOnly, similarThumbnailHover, isRestricted)
    default:
      return false
  }
}
