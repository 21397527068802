import { Divider } from 'antd'
import { useAttachUploadedFiles } from 'features/sump'
import ContextMenuUploadedFile from 'features/uploaded-file/ui/context-menu/ContextMenuUploadedFile'
import CaseSearch from 'features/uploaded-file/ui/search/CaseSearch'
import { useUploadedFileTabContext } from 'features/uploaded-file/ui/UploadedFileTabContext'
import React, { Fragment, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOS } from 'shared/lib/hooks'
import { ButtonElement, IconElement, SpinElement, StyledText, TextElement, TitleElement } from 'shared/ui/kit'
import { ActionWrapper } from 'shared/ui/table'
import styled from 'styled-components/macro'
import { useOnClickOutside } from 'viewer/map/layers/annotations/lib/hooks/useOnClickOutside'

import StackedPreviewImage from './StackedPreviewImage'
import UploadedFileDescription from './UploadedFileDescription'
import UploadedFilePreviewImage from './UploadedFilePreviewImage'

const StyledIcon = styled(IconElement)`
  color: var(--color-green);
  width: 104px;
  height: 104px;
  left: 30% !important;
`

const ActionFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
`

const Wrapper = styled(ActionWrapper)`
  width: 262px;
  min-width: 262px;
  padding: 16px;
  overflow: auto;
`
const StyledDivider = styled(Divider)`
  &.ant-divider-horizontal {
    margin: 0;
  }
`

const StyledButton = styled(ButtonElement)`
  color: var(--color-red);
  width: 100%;
  &.ant-btn-default {
    color: var(--color-red);
  }
`

const SelectedFilesCount = styled(TextElement)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`

type Props = {
  /** Функция для удаления файла. */
  onDelete: (uploadedFileIds: number[]) => void
}

const FileAttachAction = ({ onDelete }: Props) => {
  const { t } = useTranslation()
  const { isLoading, mutateAsync: attachFiles } = useAttachUploadedFiles()
  const { selectedFile, selectedFiles } = useUploadedFileTabContext()

  const menuRef = useRef(null)
  const [menuPosition, setMenuPosition] = useState<number[]>([])
  const [rotateLabel, setRotateLabel] = useState<number>(0)

  const lastThreeFiles = useMemo(() => selectedFiles.slice(-3).reverse(), [selectedFiles])
  const fileIdsToDelete = useMemo(() => selectedFiles.map(({ uploadedFileId }) => uploadedFileId), [selectedFiles])

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setMenuPosition([e.clientX, e.clientY])
  }

  const onCloseMenu = () => setMenuPosition([])

  const handleRotateLabel = () => {
    setRotateLabel((prevRotate) => prevRotate + 90)
    onCloseMenu()
  }

  useOnClickOutside(menuRef, onCloseMenu, undefined, menuPosition)

  const isShowContextMenu = useMemo(
    () =>
      !!menuPosition.length &&
      (selectedFile?.labelUrl || selectedFiles[0]?.labelUrl) &&
      (selectedFile || selectedFiles.length === 1),
    [menuPosition, selectedFile, selectedFiles],
  )

  if (!selectedFile && !selectedFiles.length) {
    return (
      <Wrapper>
        <ActionPlaceholder />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <SpinElement spinning={isLoading} indicator={<StyledIcon name="done" />}>
        {isShowContextMenu && (
          <ContextMenuUploadedFile handleRotateLabel={handleRotateLabel} menuPosition={menuPosition} />
        )}
        <ActionFlex>
          {selectedFile && !selectedFiles.length && (
            <Fragment>
              <UploadedFilePreviewImage
                rotateLabel={rotateLabel}
                handleContextMenu={handleContextMenu}
                selectedFile={selectedFile}
              />
              <UploadedFileDescription selectedFile={selectedFile} />
              <StyledButton onClick={() => onDelete([selectedFile?.uploadedFileId])}>{t('Удалить')}</StyledButton>
              <StyledDivider />
            </Fragment>
          )}
          {selectedFiles.length > 0 && (
            <Fragment>
              <StackedPreviewImage>
                {lastThreeFiles.map((file) => (
                  <UploadedFilePreviewImage
                    key={file.uploadedFileId}
                    rotateLabel={selectedFiles.length === 1 ? rotateLabel : undefined}
                    handleContextMenu={handleContextMenu}
                    selectedFile={file}
                  />
                ))}
              </StackedPreviewImage>
              {lastThreeFiles.length === 1 ? (
                <UploadedFileDescription selectedFile={lastThreeFiles[0]} />
              ) : (
                <SelectedFilesCount>{`${t('Выбрано')}: ${selectedFiles.length}`}</SelectedFilesCount>
              )}
              <StyledButton onClick={() => onDelete(fileIdsToDelete)}>{t('Удалить')}</StyledButton>
              <StyledDivider />
            </Fragment>
          )}
          <CaseSearch attachFiles={attachFiles} />
        </ActionFlex>
      </SpinElement>
    </Wrapper>
  )
}

export default FileAttachAction

const ActionPlaceholder = () => (
  <ActionFlex data-testid="action-placeholder">
    <IconElement
      name="slidePlaceholder"
      style={{ backgroundColor: 'var(--color-bg-1)', color: 'var(--color-bg-4)', height: 114, width: 230 }}
    />
    <PlaceholderText />
    <IconElement name="actionArrow" style={{ height: 73, width: 73 }} />
  </ActionFlex>
)

const PlaceholderText = () => {
  const { t } = useTranslation()
  const modifierKey = useOS() === 'MacOS' ? 'cmd' : 'ctrl'

  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', gap: 8 }}>
      <TitleElement level={5}>{t('Выберите слайд для привязки')}</TitleElement>
      <StyledText style={{ textAlign: 'center' }} isellipsis={false}>
        {`${t('Выбрать несколько слайдов можно через')} ${modifierKey}`}
      </StyledText>
    </div>
  )
}
