import 'antd/es/date-picker/style/index'

import { CasesTableFilter } from 'features/cases-management/ui/filters/CasesTableFIlter'
import React, { RefObject } from 'react'
import i18next from 'shared/lib/i18n/i18n'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import TableLayout from 'shared/ui/table/ui/TableLayout'

import CasesTabsContainer from './tabs/CasesTabsContainer'

export const t = i18next.t

type Props = {
  /** Ссылка на кнопку уведомления. */
  notificationButton?: RefObject<HTMLDivElement | null>
}
export const CasesManagementRoute = ({ notificationButton }: Props) => {
  const { isCaseRouting } = useSettingsAndUserRoles()
  return (
    <TableLayout notificationButton={notificationButton}>
      {isCaseRouting && <CasesTableFilter />}
      <CasesTabsContainer />
    </TableLayout>
  )
}
